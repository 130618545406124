<spb-breadcrumbs [links]="[
        'https://www.sparbankensyd.se/om-sparbanken-syd/kundskydd/',
        'https://www.sparbankensyd.se/om-sparbanken-syd/kundskydd/kundkannedom/']"
                 path="Kundskydd | Kundkännedom | Logga in"></spb-breadcrumbs>
<div class="spb-plain-content">
  <h1 class="mat-display-1 header">Inloggning</h1>
  <div>

    <div class="text-container mat-elevation-z8">

      <p>Här loggar du in för att hjälpa din kund att fylla i kundkännedom för en minderårig.
        Börja med att logga in med ditt BankID. När kundkännedomen är klar ska kundens förmyndare själva signera med
        respektive BankID.
      </p>

      <spb-bankid
          class="margin-top-medium"
          mode="login"
          [bankIdUrl]="environment.authServiceUrl"
          [domain]="environment.domain"
          (accessToken)="onSuccessLogin($event)"
      ></spb-bankid>
    </div>
  </div>
</div>
