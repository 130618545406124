import {Directive, HostListener} from '@angular/core'
import {StepperSelectionEvent} from '@angular/cdk/stepper'
import {MatStepper} from '@angular/material/stepper'

@Directive({
  selector: '[spbStepperScroll]',
})
export class StepperScrollDirective {

  constructor(private stepper: MatStepper) {
  }

  @HostListener('selectionChange', ['$event'])
  selectionChanged(selection: StepperSelectionEvent) {
    // Cannot fix this since it is part of stepper and I cannot control that. /Daniel
    // eslint-disable-next-line no-underscore-dangle
    const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1)
    this.scrollElement(stepId)
  }

  private scrollElement(elemId: string): void {
    const stepElement: HTMLElement = document.getElementById(elemId) as HTMLElement
    if (stepElement) {
      setTimeout(() => {
        stepElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      }, 250)
    }
  }
}
