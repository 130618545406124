import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

const ACCESS_TOKEN_NAME = 'kyc-token'


export interface BankIDResult {
  accessToken: string
}

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * If we are administrators we can do stuff.
   */
  admin?: boolean

  /**
   * The logged in person personnummer.
   */
  personNummer?: string

  /**
   * Set by the HttpIncerceptor when a token is ready to be used
   */
  interceptorReady?: boolean

  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean

  /**
   * Type indicates if this comes from a "sign" or a "login"
   */
  type?: string
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public bankIdResult: BehaviorSubject<BankIDResult> = new BehaviorSubject<BankIDResult>({accessToken: ''})


  public configState: BehaviorSubject<SpbConfiguration> =
    new BehaviorSubject<SpbConfiguration>({ready: false})

  private currentConfig: SpbConfiguration = {ready: false}

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.isLoggedIn()
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): boolean {
    const accessToken = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    // The set token will throw if not a valid string
    try {
      this.setToken({accessToken} as any)
      return true
    } catch (e) {
      // Can happen and is quite common.
      return false
    }
  }


  /**
   * Set the authentication token
   *
   * @param res - The token as received from the login service
   */
  public setToken(res: any): void {
    const payload = JSON.parse(atob(res.accessToken.split('.')[1]))
    this.currentConfig = {
      token: payload.exp > new Date().getTime() ? res.accessToken : this.resetToken(),
      personNummer: payload.sub,
      ready: true,
      type: res.type
    }
    this.configState.next(this.currentConfig)
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, res.accessToken)

  }

  /**
   * Reset what ever access token personNummer: payload.sub,e might have had
   */
  public resetToken(): undefined {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {ready: false}
    this.configState.next(this.currentConfig)
    return undefined
  }

  public getAccessToken(): string | undefined | null {
    return this.currentConfig.token
  }
}
