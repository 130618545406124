import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {HeaderComponent} from './header/header.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {CommonRoutingModule} from './common-routing.module'
import {FormsModule} from '@angular/forms'
import {StepperScrollDirective} from './directives/stepper-scroll/stepper-scroll.directive'
import {MatDialogModule} from '@angular/material/dialog'
import {SupervisorComponent} from './supervisor/supervisor.component'
import {MatCardModule} from '@angular/material/card'
import {MatButtonModule} from '@angular/material/button'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {LoginComponent} from './login/login.component'
import {MatIconModule} from '@angular/material/icon'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    StepperScrollDirective,
    SupervisorComponent,
    LoginComponent,
    WaitDialogComponent
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    BankIdModule,
    MatDialogModule,
    MatIconModule,
    ThemeModule
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    StepperScrollDirective,
    SupervisorComponent,
    WaitDialogComponent
  ]
})
export class SpbCommonModule {
}
